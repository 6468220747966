<template>
  <div class="m-dxby-nav-wrap">
    <router-link
      v-for="(item, key) in navData"
      :key="key"
      :to="item.pageUrl"
      exact
    >{{item.name}}</router-link>
  </div>
</template>

<script>
import { navData } from '@/utils/constantManage'

export default {
  name: 'Nav',
  data () {
    return {
      navData
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/common.less';
.m-dxby-nav-wrap {
  .wh(100%, 50px);
  .bg(#015462);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  a {
    flex: 1;
    color: #fff;
    .fsize(14px);
  }
  .active {
    color: #88c2f2;
    font-weight: bold;
  }
}
</style>
