<template>
  <div class="container">
    <div class="container-con">
      <!-- Header S -->
      <Head
        :backIsShow="true"
      />
      <!-- Header E -->
      <!-- Nav S -->
      <Nav/>
      <!-- Nav E -->
      <!-- show Img S -->
      <section class="m-dxby-contact-largeImg">
        <!-- <PanoramicView :fullSceenImg="fullSceenImg"/> -->
        <van-image
          width="100%"
          :src="intrduceBigImg"
        />
      </section>
      <section class="m-dxby-intruduce-largeImg-title">
        <span>专注针织面料31年 SINCE 1991</span>
        <h3>品牌服饰面料供应商</h3>
      </section>
      <section class="m-dxby-intruduce-largeImg-list">
        <img src="@/assets/images/introduce/bigImglist.png" alt="">
      </section>
      <!-- show Img E -->
      <!-- content S -->
      <section class="m-dxby-contact-content-wrap">
        <div class="m-dxby-contact-content-cp-img-wrap">
          <van-image
            width="130"
            :src="title"
          />
        </div>
        <div class="m-dxby-contact-content" v-html="aContent"></div>
      </section>
      <!-- content E -->
      <!-- 我们的优势 S -->
      <section class="m-dxby-advantage-wrap">
        <div class="m-dxby-adv-title">
          <van-image
            width="140"
            fit="contain"
            :src="advTitle"
            class="m-dxby-contact-adv-img"
          />
        </div>
        <ul>
          <li>
            <img
              :src="advantageIcons[0]"
              alt=""
              class="m-dxby-advantage-icon"
            >
            <div class="m-dxby-contact-adv-1">
              <span>{{advantage[0].key}}</span>
              <span v-html="advantage[0].value"></span>
            </div>
          </li>
          <li>
            <div class="m-dxby-contact-adv-1">
              <span>{{advantage[1].key}}</span>
              <span v-html="advantage[1].value"></span>
            </div>
            <img
              :src="advantageIcons[1]"
              alt=""
              class="m-dxby-advantage-icon"
            >
          </li>
          <li>
            <img
              :src="advantageIcons[2]"
              alt=""
              class="m-dxby-advantage-icon"
            >
            <div class="m-dxby-contact-adv-1">
              <span>{{advantage[2].key}}</span>
              <span v-html="advantage[2].value"></span>
            </div>
          </li>
          <li>
            <div class="m-dxby-contact-adv-1">
              <span>{{advantage[3].key}}</span>
              <span v-html="advantage[3].value"></span>
            </div>
            <img
              :src="advantageIcons[3]"
              alt=""
              class="m-dxby-advantage-icon"
            >
          </li>
        </ul>
      </section>
      <!-- 我们的优势 E -->
    </div>
    <Foot/>
  </div>
</template>

<script>
import axios from 'axios'
import Head from '@/components/Head'
import Nav from '@/components/Nav'
import Foot from '@/components/Foot'
import metaLable from '@/utils/metaLable'
// import PanoramicView from '@/components/PanoramicView'
import { Image as VanImage } from 'vant'
import { largeImgList } from '@/utils/constantManage'
import { hostApi } from '@/utils/requestApi'
import {
  COMPANY_TITLE,
  COMPANY_ADV_TITLE,
  COMPANY_ADV_ICON1,
  COMPANY_ADV_ICON2,
  COMPANY_ADV_ICON3,
  COMPANY_ADV_ICON4,
  COMPANY_INTRODUCE_IMG
  // COMPANY_360FULLSCREEN
} from '@/utils/imageManage'

export default {
  name: 'introduces',
  metaInfo: {
    title: '大新布业 - 企业介绍',
    meta: metaLable
  },
  data () {
    return {
      intrduceBigImg: COMPANY_INTRODUCE_IMG,
      // fullSceenImg: COMPANY_360FULLSCREEN,
      title: COMPANY_TITLE,
      advTitle: COMPANY_ADV_TITLE,
      aContent: '',
      advantage: [],
      advantageIcons: [
        COMPANY_ADV_ICON1,
        COMPANY_ADV_ICON2,
        COMPANY_ADV_ICON3,
        COMPANY_ADV_ICON4
      ],
      largeImgList
    }
  },
  mounted () {
    this.getTextContent()
  },
  methods: {
    async getTextContent () {
      const res = await axios.get(`${hostApi}/introduction/get`)
      const { info, superiority } = res.data
      this.aContent = info
      this.advantage = superiority
    }
  },
  components: {
    Head,
    Nav,
    Foot,
    VanImage
    // PanoramicView
  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
// 大图内容
.m-dxby-contact-largeImg {
  img {
    width: 100%;
  }
}
.m-dxby-intruduce-largeImg-title {
  margin-top: 20px;
  span, h3 {
    display: block;
    text-align: center;
  }
  span {
    font-size: 14px;
  }
  h3 {
    font-size: 20px;
    margin-top: 8px;
    margin-bottom: 15px;
  }
}
.m-dxby-intruduce-largeImg-menu {
  margin: 12px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .m-dxby-intruduce-largeImg-Grade {
    font-size: 20px;
    color: #000;
    .m-dxby-intruduce-star {
      img {
        display: inline-block;
        margin-right: 3px;
      }
    }
  }
  i {
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    background-color: #99bac0;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    display: block;
  }
}
.m-dxby-intruduce-largeImg-list {
  margin: 0 12px 5px;
  img {
    width: 100%;
  }
}
// 公司简介内容
/deep/.m-dxby-contact-content-wrap {
  font-family: 'Adobe 楷体 Std';
  padding: 0 24/2px;
  .m-dxby-contact-content-cp-img-wrap {
    text-align: center;
    margin: 38px 0 28px;
  }
  .m-dxby-contact-content {
    text-indent: 30px;
    font-size: 14px;
    color: #333;
    line-height: 25px;
    text-align: justify;
  }
}
// 我们的优势
.m-dxby-advantage-wrap {
  margin: 50px 10px 60px;
  .m-dxby-adv-title {
    margin-bottom: 42px;
    text-align: right;
    .m-dxby-contact-adv-img {
      display: block;
      margin: 0 auto;
    }
  }
  ul {
    li {
      width: 100%;
      margin-top: 25px;
      display: flex;
      .m-dxby-advantage-icon {
        .wh(60px, 60px);
        margin-right: 10px;
        margin-top: 10px;
      }
      .m-dxby-contact-adv-1 {
        .w(200px);
        span {
          font-size: 14px;
          color: #333;
          line-height: 20px;
          display: block;
        }
        span:nth-child(1) {
          font-weight: bold;
          margin-bottom: 2px;
        }
        span + span {
          font-size: 10px;
          color: #666;
          .otx(3);
        }
      }
    }
    li:first-child {
      .m-dxby-advantage-icon {
        margin-top: 2px;
      }
    }
    li:nth-child(2n - 2) {
      text-align: right;
      justify-content: flex-end;
      .m-dxby-advantage-icon {
        margin-right: 0;
      }
      .m-dxby-contact-adv-1 {
        margin-right: 10px;
      }
    }
  }
}
// 设备最小尺寸
@media screen and (width: 320px) {
  .m-dxby-intruduce-largeImg-list {
    ul {
      li {
        font-size: 12px;
      }
    }
  }
}
</style>
